.App {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1em;
}

.Btn {
  padding: 1em;
  margin-top: 1em;
  font-size: 0.75em;
}

#webhookUrl {
  width: 50em;
}

.footer {
  font-size: 0.5em;
  padding-top: 10em;
}
